export const MAP_DRAWING_MODE_UNDEFINED = '';
export const MAP_DRAWING_MODE_POLYGON = 'polygon';

export const PERSIST_KEY = "root20240318-state";

export const hindranceChoices = [
  {name:"Välj alternativ",value:"0"},
  {name:"Taket har inga hinder",value:"1"},
  {name:"Taket har flera hinder",value:"2"},
  {name:"Skorsten",value:"3"},
  {name:"Takfönster",value:"4"},
  {name:"Takkupa",value:"5"},
  {name:"Ventilation",value:"6"},
  {name:"Stege",value:"7"}
];

export const roofTypeChoices = [
  {name:"Välj alternativ",value:"0"},
  {name:"Plåt",value:"1"},
  {name:"Tegelpannor",value:"2"},
  {name:"Betongpannor",value:"3"},
  {name:"Papp",value:"4"},
  {name:"Eternit",value:"5"},
  {name:"Annat material",value:"6"}
];
