import address from "./address";
import roofs from "./roofs";
import map from "./map";
import details from "./details";
import result from "./result";
import { combineReducers } from "redux";

const appReducer = combineReducers({
  address,
  roofs,
  map,
  details,
  result
});

const rootReducer = (state, action) => {
  let rootState = undefined; // State undefined will reset reducer to initial state
  if (action.type === "RESET") {
    state.roofs = roofs(rootState, action);
    state.details = details(rootState, action);
    state._persist = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
