import {
  ADD_ROOF,
  REMOVE_ROOF,
  UPDATE_ROOF_SPACE,
  UPDATE_ROOF_DETAILS,
  SET_EDIT_ROOF_OPEN,
  SET_SELECTED_ROOF,
  SET_ROOF_MOBILE_MENU_OPEN,
  ADD_RESULT_ROOF,
  REMOVE_RESULT_ROOF,
  CLEAN_ROOFS,
  CREATE_ROOF_SQUARE
} from "./types";
import { utils } from "services";

export const initialState = {
  roofs: [],
  addRoofDialogOpen: false,
  selectedRoofId: null,
  roofMobileMenuOpen: false
};

export const roofs = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ROOF:
      return {
        ...state,
        roofs: state.roofs.concat(action.roof),
        selectedRoofId: action.roof.id,
      };
      case CREATE_ROOF_SQUARE:
      let mapCenter = action.mapCenter;
      const pointDistance = 0.00005;
      let paths =
       [{lat: mapCenter.lat - pointDistance, lng: mapCenter.lng - pointDistance},
        {lat: mapCenter.lat + pointDistance, lng: mapCenter.lng - pointDistance},
        {lat: mapCenter.lat + pointDistance, lng: mapCenter.lng + pointDistance},
        {lat: mapCenter.lat - pointDistance, lng: mapCenter.lng + pointDistance}];

        let newRoof = {
          id: utils.uuidv4(),
          name: "",
          space: paths,
          area: 63,
          southPosition: "0",
          obstacles: "0",
          roofType: "0",
          colorIndex: action.colorIndex
        }

      return {
        ...state,
        roofs: state.roofs.concat(newRoof),
        selectedRoofId: newRoof.id
      };
    case REMOVE_ROOF:
      return {
        ...state,
        roofs: state.roofs.filter(roof => roof.id !== action.roofId),
        selectedRoofId: null
      };
    case UPDATE_ROOF_SPACE:
      return {
        ...state,
        roofs: state.roofs.map(roof => {
          if (roof.id === action.roofId) {
            return {
              ...roof,
              space: action.space,
              area: action.area
            };
          }
          return roof;
        })
      };
    case UPDATE_ROOF_DETAILS:
      return {
        ...state,
        addRoofDialogOpen: false,
        roofs: state.roofs.map(roof => {
          if (roof.id === action.roofId) {
            return {
              ...roof,
              ...action.details
            };
          }
          return roof;
        })
      };
    case SET_EDIT_ROOF_OPEN:
      return {
        ...state,
        addRoofDialogOpen: action.payload
      };
    case SET_SELECTED_ROOF:
      return {
        ...state,
        selectedRoofId: action.payload
      };
    case SET_ROOF_MOBILE_MENU_OPEN:
      return {
        ...state,
        roofMobileMenuOpen: action.payload
      };
    case ADD_RESULT_ROOF:
      return {
        ...state,
        selectedResultRoofIds: state.selectedResultRoofIds.concat(
          action.payload
        )
      };
    case REMOVE_RESULT_ROOF:
      return {
        ...state,
        selectedResultRoofIds: state.selectedResultRoofIds.filter(
          roofId => roofId !== action.payload
        )
      };
    case CLEAN_ROOFS:
    let tempRoofs = state.roofs.filter(roof => roof.name !== "");
    let roofCount = 0;

    if(tempRoofs.length === 1){
      tempRoofs[0].name = "Tak"
    } else {
      for (var i = 0; i < tempRoofs.length; i++) {
        if(tempRoofs[i].roofType !== '5'){
          tempRoofs[i].name = "Tak " + (++roofCount);
        }
      }
    }
      return {
        ...state,
        roofs: tempRoofs
      };
    default:
      return state;
  }
};

export default roofs;
