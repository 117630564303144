import {
  ADD_ROOF,
  REMOVE_ROOF,
  UPDATE_ROOF_SPACE,
  UPDATE_ROOF_DETAILS,
  SET_EDIT_ROOF_OPEN,
  SET_SELECTED_ROOF,
  SET_ROOF_MOBILE_MENU_OPEN,
  CLEAN_ROOFS,
  CREATE_ROOF_SQUARE
} from "./types";
import { utils } from "services";

export const addRoof = (roofCoords, area) => ({
  type: ADD_ROOF,
  roof: {
    id: utils.uuidv4(),
    name: "",
    space: roofCoords,
    area: area,
    southPosition: "0",
    obstacles: "0",
    roofType: "0"
  }
});

export const createRoofSquare = (mapCenter, colorIndex) => ({
  type: CREATE_ROOF_SQUARE,
  mapCenter: mapCenter,
  colorIndex: colorIndex
});

export const cleanRoofs = () => ({
  type: CLEAN_ROOFS
});

export const removeRoof = id => ({
  type: REMOVE_ROOF,
  roofId: id
});

export const updateRoof = (id, newRoofValues) => ({
  type: UPDATE_ROOF_DETAILS,
  details: newRoofValues,
  roofId: id
});

export const updateRoofSpace = (id, newSpace, area) => ({
  type: UPDATE_ROOF_SPACE,
  space: newSpace,
  area: area,
  roofId: id
});

export const setSeletedRoof = roofId => ({
  type: SET_SELECTED_ROOF,
  payload: roofId
});

export const setEditRoofOpen = open => ({
  type: SET_EDIT_ROOF_OPEN,
  payload: open
});

export const setRoofMenuOpen = open => ({
  type: SET_ROOF_MOBILE_MENU_OPEN,
  payload: open
});
