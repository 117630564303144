export const ADD_ROOF = "ADD_ROOF";
export const REMOVE_ROOF = "REMOVE_ROOF";
export const UPDATE_ROOF_DETAILS = "UPDATE_ROOF_DETAILS";
export const UPDATE_ROOF_SPACE = "UPDATE_ROOF_SPACE";
export const SET_SELECTED_ROOF = "SET_SELECTED_ROOF";
export const SET_EDIT_ROOF_OPEN = "SET_EDIT_ROOF_OPEN";
export const SET_ROOF_MOBILE_MENU_OPEN = "SET_ROOF_MOBILE_MENU_OPEN";
export const ADD_RESULT_ROOF = "ADD_RESULT_ROOF";
export const REMOVE_RESULT_ROOF = "REMOVE_RESULT_ROOF";
export const CLEAN_ROOFS = "CLEAN_ROOFS";
export const CREATE_ROOF_SQUARE = "CREATE_ROOF_SQUARE";
