import React, { useState } from 'react';
import Slick from "react-slick";
import styles from './card.module.scss';
import { Col, Row } from 'antd';
import hybridImage from '../../../../assets/hybrid.png';
import strangImage from '../../../../assets/strang.png';
import star from '../../../../assets/star.png';

import "./slick.css";
import "./slick-theme.css";

const SolarPanelCard = ({ children, title, description, image, setCurrentCheck, currentCheck, ...props }) => {
  const [currentImage1, setCurrentImage1] = useState(0);
  const [currentImage2, setCurrentImage2] = useState(0);

  const imageArray1 = [strangImage];
  const imageArray2 = [hybridImage];

  let slickSettings;

  if (window.innerWidth < 965) {
    slickSettings = {
      dots: true,
      slidesToShow: 1,
      arrows: false
    };
  } else if (window.innerWidth < 1415) {
    slickSettings = {
      dots: true,
      slidesToShow: 2,
      arrows: false
    };
  } else {
    slickSettings = {
      dots: true,
      slidesToShow: 2,
      arrows: false
    };
  }

  return (
    <div className={styles["card__container"]}>
      <Slick {...slickSettings}>
        <div className={styles.slide}>
          <Row type="flex" className={styles.card}>
            <Col xs={24} md={24}>
              <h3 className={styles['card__title']}>
                Solcellspanel 430w <br />
                Strängväxelriktare (ej batterikompatibel)
              </h3>
            </Col>
            <Col xs={24} md={24}>
              <img alt="Bild på solpanel" className={styles['card__image']} src={imageArray1[currentImage1]} />
            </Col>
            <Col xs={24} md={24} className={styles['card__content']}>
              <div className={styles['card__description']}>
                <div className={styles['descText']} style={{ maxWidth: "500px", margin: "auto" }}>
                  <span style={{ color: "#2c2c2c" }}>
                    Det här är en glas/foliepanel för dig som vill ha en kvalitetsprodukt du kan lita på. En helsvart stilren 430w panel, med hög verkningsgrad och bra garantier. Det härdade glaset gör panelen tålig för skiftande klimat.
                  </span>
                </div>
                <Row type="flex" className={styles['miniContainer']}>
                  <Col xs={0} sm={6} />
                  <Col xs={24} sm={6}>
                    <h3 className={styles['miniHeader']}>Solcellspanel</h3>
                    <ul className={styles['miniList']}>
                      <li>Produktgaranti 25 år</li>
                      <li>Effektgaranti 30 år</li>
                    </ul>
                  </Col>
                  <Col xs={24} sm={6}>
                    <h3 className={styles['miniHeader']}>Växelriktare</h3>
                    <ul className={styles['miniList']}>
                      <li>Produktgaranti 10 år</li>
                      <li>Kompakt design</li>
                    </ul>
                  </Col>
                </Row>
                <br />

                <div style={{ bottom: "0%", width: "100%" }}>

                  <label className={styles["radioContainer"]}>
                    <span className={styles["radioText"]}>Jag är intresserad<br />av det här paketet</span>
                    <input checked={currentCheck === 1 ? "checked" : ""} readOnly onClick={() => setCurrentCheck(1)} type="radio" id="premium" />
                    <span className={styles["checkmark"]}></span>
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className={styles.slide}>
          <Row type="flex" className={styles.card}>
            <Col xs={24} md={24}>
              <h3 className={styles['card__title']}>
                Solcellspanel 430w <br />
                Hybridväxelriktare (batterikompatibel)
              </h3>
            </Col>
            <Col xs={24} md={24}>
              <img alt="Bild på solpanel" className={styles['card__image']} src={imageArray2[currentImage2]} />
            </Col>
            <Col xs={24} md={24} className={styles['card__content']}>
              <div className={styles['card__description']}>
                <div className={styles['descText']} style={{ maxWidth: "500px", margin: "auto" }}>
                  <span style={{ color: "#2c2c2c" }}>
                    Det här är en glas/foliepanel för dig som vill ha en kvalitetsprodukt du kan lita på. En helsvart stilren 430w panel, med hög verkningsgrad och bra garantier. Det härdade glaset gör panelen tålig för skiftande klimat.
                  </span>
                </div>
                <Row type="flex" className={styles['miniContainer']}>
                  <Col xs={0} sm={6} />
                  <Col xs={24} sm={6}>
                    <h3 className={styles['miniHeader']}>Solcellspanel</h3>
                    <ul className={styles['miniList']}>
                      <li>Produktgaranti 25 år</li>
                      <li>Effektgaranti 30 år</li>
                    </ul>
                  </Col>
                  <Col xs={24} sm={6}>
                    <h3 className={styles['miniHeader'] + " " + styles['leftAdjustInDesktop']}>Växelriktare</h3>
                    <ul className={styles['miniList']}>
                      <li>Produktgaranti 10 år</li>
                      <li>Kompakt design</li>
                      <li>Inbyggt överspänningsskydd</li>
                      <li>Smart mätare ingår i installationen, <br /> möjliggör tillägg av batteri</li>
                    </ul>
                  </Col>
                </Row>
                <br />

                <div style={{ bottom: "0%", width: "100%" }}>

                  <label className={styles["radioContainer"]}>
                    <span className={styles["radioText"]}>Jag är intresserad<br />av det här paketet</span>
                    <input checked={currentCheck === 2 ? "checked" : ""} readOnly onClick={() => setCurrentCheck(2)} type="radio" id="extra" />
                    <span className={styles["checkmark"]}></span>
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </div>


      </Slick>
    </div>
  )
}

export default SolarPanelCard;
